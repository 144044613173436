import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Contacts = () => {
  return (
    <Layout>
      <SEO
        title="Контакти"
        description="ТПК - Технологічна паливна компанія"
        keywords={[
          "ТПК, технологічна паливна компанія, паливо, технологическая топливная компания, топливо, нафтопродукти, нафтопродукты",
        ]}
      />
      <section className="section-contacts"></section>
    </Layout>
  );
};

export default Contacts;
